import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { rdt } from './rdt';
import { RadixDappToolkit } from "@radixdlt/radix-dapp-toolkit";
// import { appsignal } from "~/lib/appsignal";

type RDC = {
   rdt: RadixDappToolkit | undefined;
   sendTransaction: Function;
   transactionState: 'idle' | 'submitting' | 'error';
   transactionError: string | null
};

export const RadixContext = createContext<RDC>({
   rdt: rdt,
   sendTransaction: (manifest: string, message?: string) => {},
   transactionState: 'idle',
   transactionError: null,
});

const RadixProvider = (input: React.PropsWithChildren) => {
	const [transactionState, setTransactionState] = useState<'idle' | 'submitting' | 'error'>('idle');
	const [transactionError, setTransactionError] = useState<string | null>(null);

	const sendTransaction = useCallback(async (transactionManifest: string, message?: string) => {
      setTransactionState('submitting');
      setTransactionError(null);

      console.log(transactionManifest);

      const result = await rdt?.walletApi.sendTransaction({
         transactionManifest,
			version: 1,
			message
      });

      if (result?.isErr()) {
         console.error(result.error);
         setTransactionState('error');

         const message = result.error.error;//.replace('"ET failed to generate TX review: The operation couldn’t be completed. (', '');

         setTransactionError(message);

         const error = new Error(result.error.message);
         // appsignal.sendError(error, (span) => {
         //    span.setAction('Transaction');
         //    span.setNamespace(result.error.error);
         // });

         return result;
      }

      console.log('result:', result);

      setTransactionState('idle');
      setTransactionError(null);

      return result;
   }, []);

	const contextValue = useMemo(() => ({ rdt, sendTransaction, transactionState, transactionError }), [ rdt, sendTransaction, transactionState, transactionError ]);

	return <RadixContext.Provider value={contextValue}>{input.children}</RadixContext.Provider>;
};

export const useRadix = () => useContext(RadixContext);

export default RadixProvider;